export const delay = (delayInMS = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delayInMS);
  });
};


export function getSubdomain(url) {
  const urlObj = new URL(url);
  const hostname = urlObj.hostname;

  // Split the hostname into parts
  const parts = hostname.split('.');

  // Assuming a standard domain format: [subdomain].[domain].[TLD]
  // If there are more than 2 parts, there is a subdomain
  if (parts.length > 2) {
      // Remove the last two parts (domain and TLD), leaving only subdomains
      if(parts[0] == "ds-plus-app"){
          return "";
      }
      return parts[0];
  }
  
  else {
      // No subdomain present
      return '';
  }
}


export function isNumericAndTenDigits(inputString) {
  // Use a regular expression to match 10 numeric digits
  const numericRegex = /^\d{10}$/;

  // Test the input string against the regular expression
  return numericRegex.test(inputString);
}