import { ChakraProvider, Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./css/main.css";
import theme from "./theme/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Suspense from "./pages/Suspense";
import NotFound from "./pages/NotFound";
import { captchaKey } from "./constants/api-keys";
import DecideStation from "./pages/DecideStation";
import { CookiesProvider } from "react-cookie";
import { LoggedOutRoute } from "./contexts/auth";
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const HandleSSO = React.lazy(() => import("./pages/HandleSSO"));
const SSOLogin = React.lazy(() => import("./pages/SSOLogin"));
const PaymentStatus = React.lazy(() => import("./pages/PaymentStatus"));

export default function Root(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      `https://www.google.com/recaptcha/api.js?render=${captchaKey}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Box position={"fixed"} w="100vw" h="100vh" overflow={"auto"}>
      <ChakraProvider theme={theme}>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <Router basename="/">
            <React.Suspense fallback={<Suspense />}>
              <Routes>
                <Route
                  index
                  path="/payments/:status"
                  element={<PaymentStatus />}
                />
                <Route
                  path="/"
                  element={
                    <LoggedOutRoute>
                      <Outlet />
                    </LoggedOutRoute>
                  }
                >
                  <Route index path="/login" element={<DecideStation />} />
                  <Route index path="/" element={<DecideStation />} />
                  <Route index path="/signup" element={<SignUp />} />
                  <Route index path="/decide" element={<DecideStation />} />
                  <Route
                    index
                    path="/auth/sso/callback"
                    element={<HandleSSO />}
                  />
                  <Route
                    index
                    path="/sso/login/:account_id"
                    element={<SSOLogin />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </React.Suspense>
          </Router>
        </CookiesProvider>
      </ChakraProvider>
      <ToastContainer />
    </Box>
  );
}
