import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  Stack,
  useColorModeValue as mode,
  StackItem,
  Text,
  PinInputField,
  PinInput,
  Box,
  useColorModeValue,
  Icon,
  Heading,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useState, useRef, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";

import { navigateToUrl } from "single-spa";
import { loginSchema } from "../validations/yup-schemas";
import { useNavigate } from "react-router-dom";
import { captchaKey } from "../../constants/api-keys";
import { FiArrowLeft } from "react-icons/fi";
import toast from "../toast";
import {
  LOGIN_CAPTCHA_TYPE,
  GET_AUTH_TOKEN_CAPTCHA_TYPE,
  BUSINESS_USER_TYPE,
  IAM_USER_TYPE,
  GET_COMPANY_STATION,
} from "../../constants/constants";
import { Turnstile } from "@marsidev/react-turnstile";
import {
  delay,
  getSubdomain,
  isNumericAndTenDigits,
} from "../../utils/custom-functions";
import * as yup from "yup";
import { BsGoogle } from "react-icons/bs";
import { SiOkta, SiMicrosoftazure } from "react-icons/si";
import { HSeparator } from "../separator/Separator";
import { useCookies } from "react-cookie";
var counter = null;
let counterCount = 10;

const VALIDATION_SCHEMA = [
  yup.object().shape({
    name: yup.string().required(),
  }),
  yup.object().shape({
    username: yup.string().required(),
  }),
  yup.object().shape({
    login_code: yup.string().required("Verification code is required."),
  }),
];

const HEADINGS = [
  "Find your Station",
  "Found your station",
  "Check your email",
  "Finding your Station",
];

const SUBHEADINGS = [
  { pre: "We need your company alias or account id.", post: "" },
  { pre: "We need your ", post: "username" },
  "",
];

const CompanyName = (props) => {
  const [errorfield, setErrorfield] = useState({
    field: "",
    message: "",
    code: "",
  });
  const inputRef = useRef();
  const textColorBrand = mode("green.600", "white");
  const textColorDetails = mode("navy.700", "gray.600");
  const textColor = mode("navy.700", "white");
  const borderColor = mode("gray.400", "whiteAlpha.100");
  const [stepCount, setStepCount] = useState(1);
  const params =  useMemo(() => new URLSearchParams(window.location.search), []);
  const [sendingLink, setSendingLink] = useState(false);
  const [sentCounter, setSentCounter] = useState(0);
  const [ssoData, setSSOData] = useState();
  const [token, setToken] = useState();
  const [isGettingAccountID, setGettingAccountID] = useState(false);
  const [isFetchingToken, setFetchingToken] = useState(true);
  const [isFetchingAliasToken, setFetchingAliasToken] = useState(true);
  const [isFetchingTokenForAuth, setFetchingTokenAuth] = useState(true);
  const turnstile = useRef();
  const turnstileAlias = useRef();
  const turnstileAuth = useRef();
  const formikRef = useRef();
  const [cookies, setCookie] = useCookies(['authState']);
  const changeStepCount = (step) => {
    if (props?.onStepChange) props.onStepChange(step);
    setStepCount(step);
  };

  const resetAliasCaptcha = () => {
    turnstileAlias.current?.reset();
  };

  const resetCodeCaptcha = () => {
    // setFetchingToken(true);
    turnstile.current?.reset();
  };

  const resetAuthCaptcha = () => {
    // setFetchingTokenAuth(true);
    turnstileAuth.current?.reset();
  };

  useEffect(() => {
    let link = getSubdomain(window.location.origin);
    if (link !== "" && link) {
      setGettingAccountID(true);
      formikRef.current?.setFieldValue("name", link);
      setTimeout(() => {
        console.log(formikRef.current?.submitForm);
        formikRef.current?.submitForm();
      }, 1000);
      console.log("Load account id");
    }
  }, []);

  const sendLoginCode = async (name, username) => {
    const { sendLoginCodeIAMRequest } = await import(
      "@defense-station/api-service"
    );
    let token = await turnstile.current?.getResponse();
    while (!token) {
      token = await turnstile.current?.getResponse();
      await delay(1000);
    }
    const rest = await sendLoginCodeIAMRequest(name, username, token);
    return rest;
  };

  const fetchCompanyName = async (name) => {
    const { getCompanyStation } = await import("@defense-station/api-service");
    let token = await turnstileAlias.current?.getResponse();
    while (!token) {
      token = await turnstileAlias.current?.getResponse();
      await delay(1000);
    }
    const rest = await getCompanyStation({
      alias: name,
      recaptcha_code: token,
    });
    return rest?.data?.iam_v1_GetCompanyStation?.account_id;
  };

  const getSSOData = async (account_id) => {
    const { readSSORequest } = await import("@defense-station/api-service");
    const ssoResponse = await readSSORequest(account_id)
      .then((res) => res?.data?.iam_v1_GetSSO)
      .catch((e) => undefined);
    return ssoResponse;
  };

  const navigate = useNavigate();
  const onSubmit = async (values, actions) => {
    console.log("onSubmit");
    try {
      if (stepCount == 1) {
        let accountID = values?.name;
        if (isNumericAndTenDigits(accountID)) {
          changeStepCount(2);
          resetCodeCaptcha();
          actions.setSubmitting(false);
          actions.setTouched({});
        } else {
          accountID = await fetchCompanyName(values?.name);
          actions.setFieldValue("name", accountID);
          changeStepCount(2);
          resetCodeCaptcha();
          actions.setSubmitting(false);
          actions.setTouched({});
        }
        const ssoResponse = await getSSOData(accountID);
        console.log({ ssoResponse });
        if (ssoResponse) {
          setSSOData(ssoResponse);
          setCookie("authState", ssoResponse?.state);
        }
        if(params.get("username")){
          actions.setFieldValue("username", params.get('username'))
        }
        setGettingAccountID(false);
      } else if (stepCount == 2) {
        await sendLoginCode(values?.name, values?.username);
        changeStepCount(3);
        resetCodeCaptcha();
        actions.setSubmitting(false);
        actions.setTouched({});
      } else if (stepCount == 3) {
        const {
          iamSignIn,
          isRedirectBackActionSet,
          removeRedirectionBackActionForLogin,
          signOut,
        } = await import("@defense-station/auth");
        let token = await turnstileAuth.current?.getResponse();
        while (!token) {
          token = await turnstileAuth.current?.getResponse();
          await delay(1000);
        }
        await iamSignIn(values.name, values.username, values.login_code, token);
        if (isRedirectBackActionSet()) {
          removeRedirectionBackActionForLogin();
          navigate(-1);
          return;
        }
        const { getUserProfile, requestAuthTransfer } = await import(
          "@defense-station/api-service"
        );
        const res = await getUserProfile();
        const user = res?.data?.iam_v1_GetAuthInfo?.user;
        const requestedTransferTokenPayload = await requestAuthTransfer({
          account_id: user?.account_id,
          username: user?.username
        })
        await signOut();
        window.location.href = "https://app." + process.env.DOMAIN + `/auth-transfer?token=${requestedTransferTokenPayload?.data?.iam_v1_RequestAuthTransfer?.token}`
        // if (POLICY_ACCOUNTS.includes(user?.account_id)) {
        //   navigateToUrl("/policy");
        // } else {
        // if (user?.user_type == BUSINESS_USER_TYPE || user?.user_type == IAM_USER_TYPE) {
        // navigateToUrl("/management-station");
        //   navigateToUrl("/policy");
        // } else {
        //   navigateToUrl("/employee-station");
        // }
        // // }
        // changeStepCount(1);
        // resetAuthCaptcha();
      }
    } catch (e) {
      setGettingAccountID(false);
      resetCodeCaptcha();
      resetAuthCaptcha();
      resetAliasCaptcha();
      if (e.response) {
        toast({
          title: e.response.data.code,
          description: "invalid username or password",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      toast({
        title: "Error!",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setFetchingToken(true);
      turnstile.current?.reset();
    }
  };
  const resendLoginCode = async (email) => {
    try {
      setSendingLink(true);
      await sendLoginCode(email);
      setSentCounter(10);
      counterCount = 10;
      counter = setInterval(() => {
        if (counterCount == 1) {
          clearInterval(counter);
          setSentCounter(0);
        } else {
          counterCount = counterCount - 1;
          setSentCounter(counterCount);
        }
      }, 1000);
      resetCodeCaptcha();
    } catch (e) {
      resetCodeCaptcha();
      if (e.response) {
        toast({
          title: e.response.data.code,
          description: "invalid username or password",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      toast({
        title: "Error!",
        description: e.message,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }
    setSendingLink(false);
  };
  const onComplete = (code, submitForm, setFieldValue) => {
    setFieldValue("login_code", code);
    setTimeout(() => submitForm(), 100);
    // submitForm();
  };

  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA[stepCount - 1]}
      initialValues={{ name: "", username: "", login_code: "" }}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      innerRef={formikRef}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        submitForm,
        setFieldValue,
        ac,
      }) => {
        console.log({ values, errors });
        return (
          <Form>
            <Stack spacing="-px">
              <Box
                textAlign="center"
                mb={{
                  base: "4",
                  md: "8",
                }}
              >
                <Heading
                  as="h1"
                  size="xl"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                >
                  {isGettingAccountID ? HEADINGS[3] : HEADINGS[stepCount - 1]}
                </Heading>
                <Text
                  mt="1"
                  color={mode("gray.600", "gray.400")}
                  fontWeight="medium"
                >
                  {isGettingAccountID ? "" : SUBHEADINGS[stepCount - 1]?.pre}
                  <Text as={"span"} color={"brand.500"} fontWeight={"bold"}>
                  {isGettingAccountID ? "" : SUBHEADINGS[stepCount - 1]?.post}
                  </Text>
                  
                  {/* <Link ml={2} as={RouterLink} to="/signup" alignItems="center">
                    <UnderlineLink color={mode("gray.600", "gray.400")}>Sign Up</UnderlineLink>
                  </Link> */}
                </Text>
              </Box>
              {stepCount == 1 ? (
                <Stack w="full">
                  <FormLabel srOnly>Company Name</FormLabel>
                  {isGettingAccountID ? (
                    <Flex
                      position={"relative"}
                      flexDirection={"row"}
                      w="full"
                      justify={"center"}
                      align={"center"}
                    >
                      <HStack align={"center"}>
                        <Text>Getting company info...</Text>
                        <Spinner size={"sm"} />
                      </HStack>
                    </Flex>
                  ) : (
                    <Flex
                      position={"relative"}
                      flexDirection={"row"}
                      p="4"
                      borderRadius="16px"
                      borderColor="brand.700"
                      borderWidth={"1px"}
                      whiteSpace={"nowrap"}
                    >
                      <Box
                        contentEditable
                        ref={inputRef}
                        minW="2px"
                        outline="none"
                        overflow="hidden"
                        onPaste={(e) => {
                          e.preventDefault();
                          document.execCommand(
                            "inserttext",
                            false,
                            e.clipboardData.getData("text/plain")
                          );
                        }}
                        suppressContentEditableWarning
                        onClick={(e) => {
                          if (e.target.textContent == "Company-Station-Name") {
                            e.target.innerHTML = "";
                            e.target.click();
                          }
                        }}
                        onBlur={(e) => {
                          console.log(
                            "Blured",
                            e.target,
                            e.target.textContent == ""
                          );
                          if (e.target.textContent == "") {
                            console.log("Setting...");
                            e.target.innerHTML = "Company-Station-Name";
                            // e.target.click();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            if (!isSubmitting) submitForm();
                          }
                        }}
                        onInput={(e) => {
                          if (e.target.textContent?.length == 0) {
                            e.target.innerHTML = "Company-Station-Name";
                          }
                          setFieldValue("name", e.target.textContent);
                        }}
                      >
                        Company-Station-Name
                      </Box>
                      <Box
                        fontWeight={"bold"}
                        onClick={() => inputRef.current?.click()}
                      >
                        .ds.plus
                      </Box>
                    </Flex>
                  )}
                </Stack>
              ) : stepCount == 2 ? (
                <Stack w="full">
                  {ssoData && (
                    <>
                      <Button
                        w="full"
                        fontSize="sm"
                        me="0px"
                        mb="26px"
                        py="15px"
                        h="50px"
                        borderWidth="1px"
                        bg={googleBg}
                        onClick={() => (window.location.href = ssoData.url)}
                        color={"brand.700"}
                        fontWeight="500"
                        _hover={googleHover}
                        _active={googleActive}
                        _focus={googleActive}
                      >
                        <Icon
                          as={
                            ssoData.type == "gmail"
                              ? BsGoogle
                              : ssoData.type == "azuread"
                              ? SiMicrosoftazure
                              : SiOkta
                          }
                          w="20px"
                          h="20px"
                          me="10px"
                        />
                        {`Login with ${
                          ssoData.type == "gmail"
                            ? "Google"
                            : ssoData.type == "azuread"
                            ? "Azure"
                            : "Okta "
                        }`}
                      </Button>
                      <Flex align="center" mb="25px" w="full">
                        <HSeparator />
                        <Text color="gray.400" mx="14px">
                          or
                        </Text>
                        <HSeparator />
                      </Flex>
                    </>
                  )}
                  <FormControl id="username">
                    <FormLabel srOnly>Username</FormLabel>
                    <Input
                      isInvalid={
                        errorfield.field === "all" ||
                        (errors.username && touched.username)
                      }
                      size="lg"
                      name="username"
                      type="text"
                      borderRadius="16px"
                      autoComplete="username"
                      onChange={handleChange}
                      borderColor="brand.700"
                      required
                      value={values.username}
                      placeholder="Enter username..."
                      bg={mode("white", "gray.700")}
                      fontSize="md"
                      autoFocus
                    />
                  </FormControl>
                </Stack>
              ) : (
                <StackItem>
                  <Stack
                    spacing={"2"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <StackItem
                      marginBottom={"3"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Text
                        fontSize={"lg"}
                        color={mode("gray.600", "gray.400")}
                        textAlign={"center"}
                      >
                        We've sent a 6-character code to your email.
                      </Text>
                    </StackItem>
                    <StackItem>
                      <Flex>
                        <PinInput
                          autoFocus
                          isInvalid={errors?.login_code}
                          mx="auto"
                          onComplete={(code) =>
                            onComplete(code, submitForm, setFieldValue)
                          }
                          type="alphanumeric"
                        >
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "40px", md: "70px" }}
                            w={{ base: "40px", md: "70px" }}
                          />
                        </PinInput>
                      </Flex>
                    </StackItem>
                    <Turnstile
                      id="authCaptcha"
                      style={{ height: "0px", overflow: "hidden" }}
                      ref={turnstileAuth}
                      onSuccess={(token) => setFetchingTokenAuth(false)}
                      options={{
                        action: GET_AUTH_TOKEN_CAPTCHA_TYPE,
                      }}
                      siteKey={captchaKey}
                    />
                  </Stack>
                </StackItem>
              )}
              {stepCount == 2 && (
                <Turnstile
                  style={{ height: "0px", overflow: "hidden" }}
                  ref={turnstile}
                  id="codeCaptcha"
                  onSuccess={(token) => setFetchingToken(false)}
                  options={{
                    action: LOGIN_CAPTCHA_TYPE,
                  }}
                  siteKey={captchaKey}
                />
              )}
              {stepCount == 1 && (
                <Turnstile
                  style={{ height: "0px", overflow: "hidden" }}
                  ref={turnstileAlias}
                  id="aliasCaptcha"
                  onSuccess={(token) => setFetchingAliasToken(false)}
                  options={{
                    action: GET_COMPANY_STATION,
                  }}
                  siteKey={captchaKey}
                />
              )}
            </Stack>
            <LightMode>
              {!isGettingAccountID && (
                <Button
                  isLoading={
                    stepCount == 2
                      ? isFetchingToken || isSubmitting
                      : isSubmitting
                  }
                  size="lg"
                  type="submit"
                  mt="8"
                  w="full"
                  backgroundColor={"green.600"}
                  loadingText={
                    isFetchingToken || (isFetchingTokenForAuth && !isSubmitting)
                      ? "Making sure you are a human..."
                      : ""
                  }
                  colorScheme="green"
                  fontSize="md"
                  fontWeight="bold"
                >
                  {stepCount == 1 || stepCount == 2 ? "Login" : "Verify email"}
                </Button>
              )}
            </LightMode>
            {(stepCount == 3 || stepCount == 2) && (
              <Stack
                marginTop={"4"}
                spacing={"4"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
              >
                {stepCount == 3 && (
                  <StackItem>
                    <Text
                      fontWeight="400"
                      fontSize="14px"
                      color={textColorDetails}
                    >
                      Haven't received it?{" "}
                      <Button
                        fontSize="14px"
                        fontWeight="500"
                        _focus={{ boxShadow: "none" }}
                        onClick={() => resendLoginCode(values?.email)}
                        isLoading={sendingLink}
                        disabled={sentCounter}
                        variant={"link"}
                        color={textColorBrand}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        {sentCounter
                          ? "Resend a new code (" + sentCounter + ")"
                          : "Resend a new code"}
                      </Button>
                    </Text>
                  </StackItem>
                )}
                <StackItem>
                  <Button
                    onClick={() => changeStepCount(stepCount - 1)}
                    leftIcon={<FiArrowLeft />}
                    variant="link"
                    style={{ textDecoration: "none" }}
                  >
                    Go back
                  </Button>
                </StackItem>
              </Stack>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyName;
