import { createContext, useContext, useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import { isLoggedIn, removeState, removeToken } from "@defense-station/auth";
import { getUserProfile } from "@defense-station/api-service";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // We can have our existing Auth logic here, just making sure that we have a single QueryClient.
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(() => isLoggedIn());

  useEffect(() => {
    setLoggedIn(isLoggedIn());
    getUserProfile().then((user) => {
      console.log("Calling user api");
      setUser(user?.data?.iam_v1_GetAuthInfo?.user);
    }).catch(console.log);
  }, []);

  // Any user details we want can go here
  const value = {
    loggedIn,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => useContext(AuthContext);

const redirectToLogin = () => {
  navigateToUrl("/login");
};

const redirectToHome = async () => {
  const { getUserProfile } = await import("@defense-station/api-service");
  const data = await getUserProfile().catch((e) => {
    console.log(e);
  });
  const user = data?.data?.iam_v1_GetAuthInfo?.user;
  if (!user) {
    removeState();
    removeToken();
    window.location.href = "/login";
    return;
  }
  // if (window.location.origin != process.env.MANAGEMENT_APP_ENDPOINT) {
  //   if (
  //     user?.user_type == IAM_USER_TYPE ||
  //     user?.user_type == BUSINESS_USER_TYPE
  //   ) {
  //     // navigateToUrl("/management-station");
  //     navigateToUrl("/polciy");
  //   } else if (user?.user_type == SSO_USER_TYPE) {
  //     navigateToUrl("/employee-station");
  //   }
  // } else {
  navigateToUrl("/employee-station");
};

const InnerLogin = ({ children }) => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    return children;
  } else {
    redirectToLogin();
    return null;
  }
};

export const LoggedInRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogin>{children}</InnerLogin>
  </AuthProvider>
);

const InnerLogout = ({ children }) => {
  const loggedIn = isLoggedIn();
  console.log("IS logged in", loggedIn);
  if (loggedIn) {
    redirectToHome();
    return null;
  } else {
    return children;
  }
};

export const LoggedOutRoute = ({ children }) => (
  <AuthProvider>
    <InnerLogout>{children}</InnerLogout>
  </AuthProvider>
);

export const LoggedOutRouteWithUser = ({ children }) => {
  <AuthProvider>{children}</AuthProvider>;
};
